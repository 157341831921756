import React  from 'react';
import '../helpers/suppress-install-prompt';

const ClientSideOnlyLazy = React.lazy(() =>
  import("../components/ClientSideOnly")
)

const Page = () => {
  const isSSR = typeof window === "undefined"
    return (
      <>
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <ClientSideOnlyLazy />
          </React.Suspense>
        )}
      </>
    )
}

export default Page;
